import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { OrganizationService } from './organization.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private router: Router,
	            private location: Location,
	            private userService: UserService,
	            private organizationService: OrganizationService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.userService.jwtToken) {
			req = req.clone({
				headers: req.headers.append('Authorization', `Bearer ${this.userService.jwtToken}`)
			});
		}

		if (this.organizationService.currentOrganization.id) {
			req = req.clone({
				headers: req.headers.append('organization', this.organizationService.currentOrganization.alias)
			});
		}

		return next.handle(req);
	}

}
